<template>
  <div class="row justify-content-center text-center mt-5 mb-5">
    <div class="col-md-4">
      <h2 class="mb-3">Welkom op SayYes</h2>
      <p class="mb-4">Kom je er niet uit? Open de <a href="https://sayyesapp.nl/handleiding" target="_blank">handleiding</a>.</p>

      <b-alert variant="danger" :show="isError">Je inlogpoging is mislukt. Probeer opnieuw.</b-alert>

      <b-form @submit="onSubmit">
        <b-form-group
            label="Subdomein"
            label-for="subdomain"
            description="Vul hier het subdomein van je Yesplan installatie in."
        >
          <b-input-group>
            <b-form-input
                id="subdomain"
                v-model="form.subdomain"
                type="text"
                required />
            <b-input-group-append>
              <b-form-select v-model="form.domain" required>
                <b-form-select-option value="">--- Maak een keuze ---</b-form-select-option>
                <b-form-select-option value="nl">.yesplan.nl</b-form-select-option>
                <b-form-select-option value="be">.yesplan.be</b-form-select-option>
              </b-form-select>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="API-sleutel"
          label-for="apikey"
          description="Vul hier je API-sleutel in."
        >
          <b-form-input
            id="apikey"
            v-model="form.apikey"
            type="password"
            required />
        </b-form-group>

        <b-button type="submit" variant="info">Log in</b-button>
      </b-form>
    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex"
export default {
  data() {
    return {
      isError: false,
      isErrorActivated: false,
      form: {
        subdomain: '',
        apikey: '',
        domain: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'error'])
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.isError = false

      if(this.form.domain.length === 0) {
        this.isError = true
      }
      this.$store.dispatch('login', this.form).then(() => {
        this.$router.push('/')
      }).catch(() => {
        if(this.error === 'login_failed')
          this.isError = true
      })
    }
  },
  mounted() {
    if(this.isLoggedIn) {
      this.$router.push("/")
    }
  }
}
</script>